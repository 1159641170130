import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import anime from 'animejs'
import styled from 'styled-components'
import { IconLoader } from '@components/icons'

const StyledLoader = styled.div`
	${({ theme }) => theme.mixins.flexCenter};
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: var(--dark-navy);
	z-index: 99;

	.logo-wrapper {
		width: max-content;
		max-width: 250px;
		transition: var(--transition);
		opacity: ${props => (props.isMounted ? 1 : 0)};
		svg {
			display: block;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			fill: none;
			user-select: none;
			animation: fill 450ms 2s forwards ease-in-out;

			#RK {
				opacity: 0;
			}
		}

		@keyframes fill {
			from {
				fill: transparent;
			}
			to {
				fill: var(--blue);
			}
		}
	}
`

const Loader = ({ finishLoading }) => {
	const [isMounted, setIsMounted] = useState(false)

	const animate = () => {
		const loader = anime.timeline({
			complete: () => finishLoading(),
		})

		loader
			.add({
				targets: '#logo path',
				delay: (_, i) => i * 350,
				duration: 1500,
				easing: 'easeInOutBack',
				strokeDashoffset: [anime.setDashoffset, 0],
			})
			.add({
				targets: '#logo #RK',
				duration: 700,
				easing: 'easeInOutQuart',
				opacity: 1,
			})
			.add({
				targets: '#logo',
				delay: 500,
				duration: 300,
				easing: 'easeInOutQuart',
				opacity: 0,
				scale: 0.1,
			})
			.add({
				targets: '.loader',
				duration: 200,
				easing: 'easeInOutQuart',
				opacity: 0,
				zIndex: -1,
			})
	}

	useEffect(() => {
		const timeout = setTimeout(() => setIsMounted(true), 10)
		animate()
		return () => clearTimeout(timeout)
	}, [])

	return (
		<StyledLoader className="loader" isMounted={isMounted}>
			<Helmet bodyAttributes={{ class: `hidden` }} />

			<div className="logo-wrapper">
				<IconLoader />
			</div>
		</StyledLoader>
	)
}

Loader.propTypes = {
	finishLoading: PropTypes.func.isRequired,
}

export default Loader
