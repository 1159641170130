import React, { useState, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import { email } from '@config'
import { navDelay, loaderDelay } from '@utils'
import { BgAnimation } from '@components'

const StyledHeroSection = styled.section`
	${({ theme }) => theme.mixins.flexCenter};
	flex-direction: column;
	align-items: flex-start;
	min-height: 100vh;
	height: 100vh;
	padding: 0;

	@media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
		height: auto;
		padding-top: var(--nav-height);
	}

	h1 {
		margin: 0 0 30px 4px;
		color: var(--blue);
		font-family: var(--font-mono);
		font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
		font-weight: 400;

		@media (max-width: 480px) {
			margin: 0 0 20px 2px;
		}
	}

	h3 {
		margin-top: 10px;
		color: var(--slate);
		line-height: 1;
	}

	p {
		margin: 30px 0 0;
		max-width: 540px;
	}

	.email-link {
		${({ theme }) => theme.mixins.bigButton};
		margin-top: 50px;
	}
`

const Hero = () => {
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => {
		const timeout = setTimeout(() => setIsMounted(true), navDelay)
		return () => clearTimeout(timeout)
	}, [])

	const one = <h1>Hi, my name is</h1>
	const two = <h2 className="big-heading">Rahul RK.</h2>
	const three = <h3 className="big-heading">I build things for the web.</h3>
	const four = (
		<p>
			I'm a passionate software developer with expertise in the latest technologies. I enjoy
			collaborating with my team to deliver high-quality code that meets end-users' needs. Let's
			build something great together!
		</p>
	)
	const five = (
		<a href={`mailto:${email}`} className="email-link">
			Get In Touch
		</a>
	)

	const items = [one, two, three, four, five]

	return (
		<StyledHeroSection>
			<TransitionGroup component={null}>
				{isMounted &&
					items.map((item, i) => (
						<CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
							<div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
						</CSSTransition>
					))}
			</TransitionGroup>
			<TransitionGroup component={null}>
				{isMounted && (
					<CSSTransition classNames="fade" timeout={loaderDelay}>
						<BgAnimation delay={`${items.length + 3}00ms`} />
					</CSSTransition>
				)}
			</TransitionGroup>
		</StyledHeroSection>
	)
}

export default Hero
