import mixins from './mixins'

const theme = {
	bp: {
		mobileS: `screen and (max-width: 330px)`,
		mobileM: `screen and (max-width: 400px)`,
		mobileL: `screen and (max-width: 480px)`,
		tabletS: `screen and (max-width: 600px)`,
		tabletL: `screen and (max-width: 768px)`,
		desktopXS: `screen and (max-width: 900px)`,
		desktopS: `screen and (max-width: 1080px)`,
		desktopM: `screen and (max-width: 1200px)`,
		desktopL: `screen and (max-width: 1400px)`,
	},

	mixins,
}

export default theme
