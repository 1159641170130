import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { srConfig } from '@config'
import sr from '@utils/sr'

const StyledAboutSection = styled.section`
	max-width: 1000px;

	.inner {
		display: grid;
		grid-template-columns: 3fr 2fr;
		grid-gap: 50px;

		@media (max-width: 768px) {
			display: block;
		}
	}
`

const StyledText = styled.div`
	ul.skills-list {
		display: grid;
		grid-template-columns: repeat(2, minmax(140px, 200px));
		grid-gap: 0 10px;
		padding: 0;
		margin: 20px 0 0 0;
		overflow: hidden;
		list-style: none;

		li {
			position: relative;
			margin-bottom: 10px;
			padding-left: 20px;
			font-family: var(--font-mono);
			font-size: var(--fz-xs);

			&:before {
				content: '♦';
				position: absolute;
				left: 0;
				color: var(--blue);
				font-size: var(--fz-sm);
				line-height: 16px;
			}
		}
	}
`

const StyledPic = styled.div`
	position: relative;
	max-width: 300px;

	@media (max-width: 768px) {
		margin: 50px auto 0;
		width: 70%;
	}

	.wrapper {
		${({ theme }) => theme.mixins.boxShadow};
		display: block;
		position: relative;
		width: 100%;
		border-radius: var(--border-radius);
		background-color: var(--blue);

		&:hover,
		&:focus {
			outline: 0;

			&:after {
				top: -15px;
				left: 15px;
				// transform: translate(-8px, 8px);
			}

			.img {
				filter: none;
				mix-blend-mode: normal;
			}
		}

		.img {
			position: relative;
			border-radius: var(--border-radius);
			mix-blend-mode: multiply;
			filter: grayscale(100%) contrast(1);
			transition: var(--transition);
		}

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: var(--border-radius);
			transition: var(--transition);
		}

		&:before {
			top: 0;
			left: 0;
			background-color: var(--navy);
			mix-blend-mode: screen;
		}

		&:after {
			border: 2px solid var(--blue);
			box-shadow: 0 0 8px 4px var(--blue-tint), inset 0 0 8px 4px var(--blue-tint);
			top: -20px;
			left: 20px;
			z-index: -1;
		}
	}
`

const About = () => {
	const data = useStaticQuery(graphql`
		query {
			avatar: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "me.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 500, traceSVG: { color: "#43b9ff" }) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	const revealContainer = useRef(null)

	useEffect(() => {
		sr.reveal(revealContainer.current, srConfig())
	}, [])

	const skills = [
		'JavaScript (ES6+)',
		'Node.js',
		'MongoDB',
		'React',
		'TypeScript',
		'AWS',
		'Git',
		'Jest',
		'Redis',
		'MySQL',
		'Graphql',
		'Nest.js',
		'Python',
		'Bash Scripts',
		'HTML & (S)CSS',
		'Bootstrap',
		'PHP',
		'jQuery',
	]

	return (
		<StyledAboutSection id="about" ref={revealContainer}>
			<h2 className="numbered-heading">About Me</h2>

			<div className="inner">
				<StyledText>
					<div>
						<p>
							Hello there! My name is Rahul and I have a passion for creating digital products that
							live on the internet, whether it's a website, application, or anything in between. I
							strive to build products that not only look pixel-perfect but also provide seamless
							and performant user experiences.
						</p>

						<p>
							Currently, I'm working at a web and app development company{' '}
							<a href="https://www.21twelveinteractive.com/">21Twelve Interactive</a> for 2+ years
							as a Senior Software Developer. I have been involved in various stages of the software
							development lifecycle, from ideation and requirements gathering to design, team lead
							and development, testing, and deployment.
						</p>
						<p>
							I have also had the opportunity to work with cutting-edge technologies and tools,
							which has helped me to stay up-to-date with the latest trends and best practices in
							the industry.
						</p>

						<p>Here are some technologies I've been working with:</p>
					</div>

					<ul className="skills-list">
						{skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
					</ul>
				</StyledText>

				<StyledPic>
					<div className="wrapper">
						<Img fluid={data.avatar.childImageSharp.fluid} alt="Avatar" className="img" />
					</div>
				</StyledPic>
			</div>
		</StyledAboutSection>
	)
}

export default About
