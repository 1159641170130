module.exports = {
	email: 'hi@rahulrk.dev',

	socialMedia: [
		{
			name: 'GitHub',
			url: 'https://github.com/rahulrk-dev',
		},
		{
			name: 'Instagram',
			url: 'https://www.instagram.com/rahulrk_dev',
		},
		{
			name: 'Twitter',
			url: 'https://twitter.com/rahulrk_dev',
		},
		{
			name: 'Linkedin',
			url: 'https://www.linkedin.com/in/rahulrk-dev',
		},
	],

	navLinks: [
		{
			name: 'About',
			url: '/#about',
		},
		{
			name: 'Experience',
			url: '/#jobs',
		},
		{
			name: 'Projects',
			url: '/#projects',
		},
		{
			name: 'Contact',
			url: '/#contact',
		},
	],

	colors: {
		blue: '#43B9FF',
		navy: '#0B1529',
		darkNavy: '#020c1b',
	},

	srConfig: (delay = 200, viewFactor = 0.25) => ({
		origin: 'bottom',
		distance: '20px',
		duration: 500,
		delay,
		rotate: { x: 0, y: 0, z: 0 },
		opacity: 0,
		scale: 1,
		easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
		mobile: true,
		reset: false,
		useDelay: 'always',
		viewFactor,
		viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
	}),
}
