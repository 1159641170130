import React from 'react'

const IconLogo = () => (
	<svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
		<title>Logo</title>
		<g transform="translate(-8.000000, -2.000000)">
			<g transform="translate(11.000000, 5.000000)">
				<path
					d="M14.18327,54.48247H19.047a1,1,0,0,1,1,1V60.3747a1,1,0,0,1-1,1H14.18327a1,1,0,0,1-1-1V55.48247A1,1,0,0,1,14.18327,54.48247Zm18.80285-3.10893a.99737.99737,0,0,1,.37523-1.46751Q38.131,47.5,38.131,40.82686q-.00018-5.36819-3.05811-7.78809t-10.23-2.41992H21.19627a1,1,0,0,0-1,1v3.896a1,1,0,0,0,1,1H25.195q3.30029.00027,4.62012.92383,1.31982.92432,1.31982,3.41016A4.64641,4.64641,0,0,1,29.859,44.47872q-1.27661,1.144-4.7959,1.144H21.19627a1,1,0,0,0-1,1v3.94046a1,1,0,0,0,1,1h3.08a1,1,0,0,1,.82111.42922L31.321,60.94548a1,1,0,0,0,.82111.42922h5.99135a1,1,0,0,0,.81617-1.57782Z"
					fill="currentColor"
				/>
				<path
					d="M40.77068,60.37471V55.48247a1,1,0,0,1,1-1h4.86426a1,1,0,0,1,1,1V60.3747a1,1,0,0,1-1,1H41.77068A1,1,0,0,1,40.77068,60.37471ZM56.04915,43.61625,66.241,32.28767a1,1,0,0,0-.74342-1.66882H59.689a1,1,0,0,0-.736.323L47.89893,42.95978a1,1,0,0,0-.264.677V50.051a1,1,0,0,0,1.7443.66784l.51749-.57674a1,1,0,0,1,1.54706.07153L59.43466,60.971a1,1,0,0,0,.80276.40369h5.56022a.99668.99668,0,0,0,.81885-1.574q-2.001-2.80154-5.254-7.38013-3.70514-5.21306-5.38712-7.55924A.99133.99133,0,0,1,56.04915,43.61625Z"
					fill="currentColor"
				/>
			</g>
		</g>
	</svg>
)

export default IconLogo
