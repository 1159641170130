import { css } from 'styled-components'

import MontserratRegularWoff from '@fonts/Montserrat/Montserrat-regular.woff'
import MontserratRegularWoff2 from '@fonts/Montserrat/Montserrat-regular.woff2'
import MontserratMediumWoff from '@fonts/Montserrat/Montserrat-500.woff'
import MontserratMediumWoff2 from '@fonts/Montserrat/Montserrat-500.woff2'
import MontserratSemiboldWoff from '@fonts/Montserrat/Montserrat-600.woff'
import MontserratSemiboldWoff2 from '@fonts/Montserrat/Montserrat-600.woff2'

import MontserratRegularItalicWoff from '@fonts/Montserrat/Montserrat-italic.woff'
import MontserratRegularItalicWoff2 from '@fonts/Montserrat/Montserrat-italic.woff2'
import MontserratMediumItalicWoff from '@fonts/Montserrat/Montserrat-500italic.woff'
import MontserratMediumItalicWoff2 from '@fonts/Montserrat/Montserrat-500italic.woff2'
import MontserratSemiboldItalicWoff from '@fonts/Montserrat/Montserrat-600italic.woff'
import MontserratSemiboldItalicWoff2 from '@fonts/Montserrat/Montserrat-600italic.woff2'

import RobotoMonoRegularWoff from '@fonts/RobotoMono/RobotoMono-regular.woff'
import RobotoMonoRegularWoff2 from '@fonts/RobotoMono/RobotoMono-regular.woff2'
import RobotoMonoSemiboldWoff from '@fonts/RobotoMono/RobotoMono-600.woff'
import RobotoMonoSemiboldWoff2 from '@fonts/RobotoMono/RobotoMono-600.woff2'

import RobotoMonoRegularItalicWoff from '@fonts/RobotoMono/RobotoMono-italic.woff'
import RobotoMonoRegularItalicWoff2 from '@fonts/RobotoMono/RobotoMono-italic.woff2'
import RobotoMonoSemiboldItalicWoff from '@fonts/RobotoMono/RobotoMono-600italic.woff'
import RobotoMonoSemiboldItalicWoff2 from '@fonts/RobotoMono/RobotoMono-600italic.woff2'

const montserratNormalWeights = {
	400: [MontserratRegularWoff, MontserratRegularWoff2],
	500: [MontserratMediumWoff, MontserratMediumWoff2],
	600: [MontserratSemiboldWoff, MontserratSemiboldWoff2],
}

const montserratItalicWeights = {
	400: [MontserratRegularItalicWoff, MontserratRegularItalicWoff2],
	500: [MontserratMediumItalicWoff, MontserratMediumItalicWoff2],
	600: [MontserratSemiboldItalicWoff, MontserratSemiboldItalicWoff2],
}

const RobotoMonoNormalWeights = {
	400: [RobotoMonoRegularWoff, RobotoMonoRegularWoff2],
	600: [RobotoMonoSemiboldWoff, RobotoMonoSemiboldWoff2],
}

const RobotoMonoItalicWeights = {
	400: [RobotoMonoRegularItalicWoff, RobotoMonoRegularItalicWoff2],
	600: [RobotoMonoSemiboldItalicWoff, RobotoMonoSemiboldItalicWoff2],
}

const montserrat = {
	name: 'Montserrat',
	normal: montserratNormalWeights,
	italic: montserratItalicWeights,
}

const RobotoMono = {
	name: 'RobotoMono',
	normal: RobotoMonoNormalWeights,
	italic: RobotoMonoItalicWeights,
}

const createFontFaces = (family, style = 'normal') => {
	let styles = ''

	for (const [weight, formats] of Object.entries(family[style])) {
		const woff = formats[0]
		const woff2 = formats[1]

		styles += `
      @font-face {
        font-family: '${family.name}';
        src: url(${woff2}) format('woff2'),
            url(${woff}) format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: auto;
      }
    `
	}

	return styles
}

const montserratNormal = createFontFaces(montserrat)
const montserratItalic = createFontFaces(montserrat, 'italic')

const RobotoMonoNormal = createFontFaces(RobotoMono)
const RobotoMonoItalic = createFontFaces(RobotoMono, 'italic')

const Fonts = css`
	${montserratNormal + montserratItalic + RobotoMonoNormal + RobotoMonoItalic}
`

export default Fonts
