import { css } from 'styled-components'

const button = css`
	color: var(--blue);
	background-color: transparent;
	border: 1px solid var(--blue);
	border-radius: var(--border-radius);
	font-size: var(--fz-xs);
	font-family: var(--font-mono);
	line-height: 1;
	text-decoration: none;
	cursor: pointer;
	transition: var(--transition);
	padding: 1.25rem 1.75rem;

	&:hover,
	&:focus,
	&:focus-visible,
	&:active {
		background-color: var(--blue-tint);
		outline: none;
		box-shadow: 4px 4px 0 0 var(--blue);
		transform: translate(-5px, -5px);
	}
	&:after {
		display: none !important;
	}
`

const mixins = {
	flexCenter: css`
		display: flex;
		justify-content: center;
		align-items: center;
	`,

	flexBetween: css`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`,

	link: css`
		display: inline-block;
		text-decoration: none;
		text-decoration-skip-ink: auto;
		color: inherit;
		position: relative;
		transition: var(--transition);
		cursor: pointer;
		&:hover,
		&:active,
		&:focus {
			color: var(--blue);
			outline: 0;
		}
	`,

	inlineLink: css`
		display: inline-block;
		text-decoration: none;
		text-decoration-skip-ink: auto;
		position: relative;
		transition: var(--transition);
		cursor: pointer;
		color: var(--blue);
		&:hover,
		&:focus,
		&:active {
			color: var(--blue);
			outline: 0;
			&:after {
				width: 100%;
			}
			& > * {
				color: var(--blue) !important;
				transition: var(--transition);
			}
		}
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 1px;
			position: relative;
			bottom: 0.07em;
			background-color: var(--blue);
			transition: var(--transition);
			opacity: 0.5;
		}
	`,

	button,

	smallButton: css`
		color: var(--blue);
		background-color: transparent;
		border: 1px solid var(--blue);
		border-radius: var(--border-radius);
		padding: 0.75rem 1rem;
		font-size: var(--fz-xs);
		font-family: var(--font-mono);
		line-height: 1;
		text-decoration: none;
		cursor: pointer;
		transition: var(--transition);
		&:hover,
		&:focus,
		&:active {
			background-color: var(--blue-tint);
			outline: none;
			box-shadow: 3px 3px 0 0 var(--blue);
			transform: translate(-4px, -4px);
		}
		&:after {
			display: none !important;
		}
	`,

	bigButton: css`
		color: var(--blue);
		background-color: transparent;
		border: 1px solid var(--blue);
		border-radius: var(--border-radius);
		padding: 1.25rem 1.75rem;
		font-size: var(--fz-sm);
		font-family: var(--font-mono);
		line-height: 1;
		text-decoration: none;
		cursor: pointer;
		transition: var(--transition);
		&:hover,
		&:focus,
		&:active {
			background-color: var(--blue-tint);
			outline: none;
			box-shadow: 4px 4px 0 0 var(--blue);
			transform: translate(-5px, -5px);
		}
		&:after {
			display: none !important;
		}
	`,

	boxShadow: css`
		box-shadow: 0 10px 30px -15px var(--navy-shadow);
		transition: var(--transition);

		&:hover,
		&:focus {
			box-shadow: 0 20px 30px -15px var(--navy-shadow);
		}
	`,

	fancyList: css`
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: var(--fz-sm);
		li {
			position: relative;
			padding-left: 30px;
			margin-bottom: 10px;
			&:before {
				content: '▹';
				position: absolute;
				left: 0;
				color: var(--blue);
			}
		}
	`,

	resetList: css`
		list-style: none;
		padding: 0;
		margin: 0;
	`,
}

export default mixins
